import Home from "../pages/Home";
import React from "react";

class HomePage extends React.Component {
    render() {
        return (
            <Home title="Website Sekelas Gacor Top World" desc="Website terbaik di dunia paling gacor"/>
        )
    }
}

export default HomePage