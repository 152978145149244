

import React from 'react'
import './bubble.css'




const Bubble = () => {
  return (
    <ul className='bubbles'>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
  )
}

export default Bubble