import { Helmet } from 'react-helmet'
import 'bootstrap/dist/js/bootstrap.min.js'
import Bubble from '../component/bubble'
import React from 'react'
 
const Home = (props) => {
    const now = new Date();
    const year = now.getFullYear();
  return (
    <>
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.desc}/>
        </Helmet>
        <main>
            <Bubble/>
            <div className='container mt-4'>
                <h1 className='text-center' style={{color:'gold'}}>
                    <span className='text-center'>
                        <img className='img-fluid' src={`${process.env.PUBLIC_URL}/logo.svg`} alt="logo" style={{width: '400px', heigh:'180px'}}/>
                    </span>
                </h1>
                <h4 className='text-center mt-4 text-light'><strong>Victory39: Situs Slot Online Anti Rungkat Paling Gacor Terpercaya di Indonesia. RTP Live 99%</strong></h4>
                <h4 className='text-center mt-4 text-light'>WELCOME BONUS 100%</h4>
                <h4 className='text-center mt-4 text-light'>BONUS DEPOSIT <span className='text-warning'>5 JUTA</span></h4>
                <h4 className='text-center mt-4 text-light'>BONUS KOMISI 1%</h4>
                <h4 className='text-center mt-4 text-light'>BONUS SPIN WHEEL <span className='text-warning'>39 JUTA</span></h4>
                <div className='row d-flex align-center justify-content-center mx-4'>
                    <div className='col-md-4 mx-4'>
                        <div className='mt-4 row align-items-center'>
                            <a href="https://www.victory39.biz/register?code=CG12YO8X" target="_blank" rel="noopener noreferrer" className="btn btn-info btn-lg mt-4 d-flex justify-content-center align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" className="bi bi-person-plus-fill" viewBox="0 0 16 16">
                                <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                                <path fillRule="evenodd" d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z" />
                                </svg>
                                <span>&nbsp;&nbsp;Daftar Akun Gacor</span>
                            </a>
                            <a href="https://wa.me/639052603099" target="_blank" rel="noopener noreferrer" className="btn btn-light btn-lg mt-2 d-flex justify-content-center align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                </svg>
                                <span>&nbsp;&nbsp;Whatsapp Official</span>
                                </a>
                                <a href="https://direct.lc.chat/14290863/" target="_blank" rel="noopener noreferrer" className="btn btn-warning btn-lg mt-2 d-flex justify-content-center align-items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="currentColor" className="bi bi-chat-left-dots" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                    <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                                <span>&nbsp;&nbsp;Livechat Official</span>
                            </a>
                        </div>
                    </div>
                </div>
                <h6 className=" text-center mt-4 text-light">Terima kasih, Untuk Mempercepat Proses Pendaftaran Anda silahkan hubungi CS kami melalui Livechat</h6>
                <div className="d-flex flex-column align-items-center">
                    <a className="text-center" href>Privacy Policy</a>
                    <a className="text-center" target="_blank" rel="noopener noreferrer" href="https://wa.me/639052603099">Hubungi Kami</a>
                </div>
                <h4 className=" text-center mt-4 text-light">{year} ALL RIGHTS RESERVED</h4>
            </div>
        </main>
    </>
  )
}

export default Home